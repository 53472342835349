import React, {useState} from "react";
import { Link } from "react-router-dom";

import "../../css/style.css";
import "../../css/contrats.css";
import LabelledTextField from "../1-atoms/LabelledTextField";
import Button from "../1-atoms/Button";
import { CustomerInfo } from "../../types";

const AGENT_LABEL = "Les modifications seront apportées par l'agent (ID) :";
const PASSWORD_LABEL = "Nouveau mot de passe - laisser vide pour ne rien changer :";
const CONFIRM_PASSWORD_LABEL = "Répéter le nouveau mot de passe :";


type Props = {
  agentId: string;
  customerInfo: CustomerInfo;
  updateCustomerInfo: (updatedInfo: CustomerInfo) => void;
  logout: () => void;
}

const CustomerFile = (props: Props) => {
  const {agentId, customerInfo, updateCustomerInfo, logout} = props;

  const [lastName, editLastName] = useState(customerInfo.lastName);
  const [firstName, editFirstName] = useState(customerInfo.firstName);
  const [phoneNumber, editPhoneNumber] = useState(customerInfo.phoneNumber);
  const [email, editEmail] = useState(customerInfo.email);
  const [password, editPassword] = useState("");
  const [confirmPassword, editConfirmPassword] = useState("");

  const modifyFile = () => {
    if (password === confirmPassword) {
      updateCustomerInfo({
        lastName,
        firstName,
        phoneNumber,
        email,
        password
      })
    }
    
  }
  return (
    <>
      <div className="intro">
        {/* TODO: A personnaliser? */}
        <h3>Bienvenue Agent</h3>
        <p>
          <Link to="/contracts/lookup">Retour</Link> | <Link to="/agent/login" onClick={logout}>Se déconnecter</Link>
        </p>
      </div>
      <br/><br/>    
      <div className="contrats_agent contrats">
        <div className="contrat">
          <h4 className="">Rechercher un contrat</h4>
          <div className="content liste">
            <form action="">
              <LabelledTextField 
                label={AGENT_LABEL} 
                name="agentId"
                id="agentId"
                placeholder = "Identifiant"
                onChange={() => {}}
                value={agentId}
                readOnly
              />
              <br/>
              <LabelledTextField 
                label="Nom du client :"
                id="clientLastname"
                name="clientLastname"
                placeholder = "Nom du client"
                value={lastName}
                onChange={editLastName}
              />
              <br/>

              <LabelledTextField 
                label="Prénom du client :"
                id="clientName"
                name="clientName"
                placeholder = "Prénom du client"
                value={firstName}
                onChange={editFirstName}
              />
              <br/>
                      
              <LabelledTextField 
                label="Téléphone du client :"
                id="clientPhone"
                name="clientPhone"
                placeholder = "Téléphone du client"
                value={phoneNumber}
                onChange={editPhoneNumber}
              />
              <br/>

              <LabelledTextField 
                label="Email du client :"
                id="clientEmail"
                name="clientEmail"
                placeholder="Email du client"
                value={email}
                onChange={editEmail}
              />
              <br/>

              <br/><br/>

              <LabelledTextField 
                label={PASSWORD_LABEL}
                id="clientPass1"
                name="clientPass1"
                placeholder="Nouveau mot de passe"
                isPassword
                value={password}
                onChange={editPassword}
              />
              <br/>
              <LabelledTextField 
                label={CONFIRM_PASSWORD_LABEL}
                id="clientPass2"
                name="clientPass2"
                placeholder="Répéter le nouveau mot de passe"
                isPassword
                value={confirmPassword}
                onChange={editConfirmPassword}
              />
              <br/><br/>

              <Button className="" 
                label="Modifier la fiche"
                onClick={modifyFile}
              />
              <Link to="/contracts/lookup">
                <Button className="buttona" 
                  label="Annuler"
                  onClick={() => {}}
                />
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerFile;