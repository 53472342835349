import { useCallback } from "react";
import { User } from "../types";
import { useState } from "react";
// export function usegetUser() {
//     const stringifiedUser = localStorage.getItem("user");
//     return stringifiedUser !== null ? JSON.parse(stringifiedUser) as User : undefined;
// };

// export const logout = () =>
//     localStorage.removeItem("user");
        
// export const login = (user: User) =>
//     localStorage.setItem("user", JSON.stringify(user));
    


export function useAuthentication() {
  const [stringifiedUser, setStringifiedUser] = useState(localStorage.getItem("user"));

  const login = useCallback(
    (user: User) => {
      const newStringifiedUser = JSON.stringify(user);
      localStorage.setItem("user", newStringifiedUser);
      setStringifiedUser(newStringifiedUser);
    },
    []
  );
  const logout = useCallback(
    () => {
        localStorage.removeItem("user");
        setStringifiedUser(null);
    },
    []
  );
  const user: User | undefined = stringifiedUser !== null ? JSON.parse(stringifiedUser) : undefined;
  return {user, login, logout};
}

