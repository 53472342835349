import React, {Dispatch, SetStateAction, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../1-atoms/Button";
import LabelledTextField from "../1-atoms/LabelledTextField";
import { Modal } from "../1-atoms/Modal";

const PASSWORD_LABEL =  "Saisissez votre mot de passe provisoire";
const NEW_PASSWORD_LABEL =  "Saisissez votre nouveau mot de passe";
const CONFIRM_PASSWORD_LABEL =  "Confirmez votre mot de passe";

const captchaKey = process.env["REACT_APP_RECAPTCHA_KEY"] ?? "  ";

type Props = {
    setModalId: Dispatch<SetStateAction<number>>;
    tokenProps?: string;
    modalIdFromLogin: number;
    register: (registerInfo: {email: string; password: string, token: string, captchaValue: string}) => void;
    updatePassword: (updateInfo: {email: string; password: string, token: string, captchaValue: string}) => void;
    login: (registerInfo: {email: string; password: string, token?: string, captchaValue: string, stayConnected?: boolean}) => void;
    resetOTP: (infos: {email?: string, phoneNumber?: string, captchaValue: string}) => void;
    resetPassword: (infos: {email: string, captchaValue: string}) => void;
 }

const LoginModal = (props: Props) => {
    const {register, tokenProps = '', setModalId, modalIdFromLogin, resetOTP, resetPassword, updatePassword} = props;

    const [token, setToken] = useState(tokenProps);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState(""); // TODO: Secure
    const [passwordVerification, setPasswordVerification] = useState(""); // TODO: Secure
    const [captchaValue, setCaptchaValue] = useState("");
    const validationPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|[@$!%*#?&]))[a-zA-Z\d@$!%*#?&]{8,}$/;
    const validationEmail = /\w+(\w|\.)*@\w+\.\w{2,3}/;

    const isEmailValid = validationEmail.test(email.toLowerCase());
    const isPasswordValid = validationPassword.test(password);

    const onLoginClick = async () => {
      switch (modalIdFromLogin) {
        case 0:
        case 2:
          if (isEmailValid) {
            if (modalIdFromLogin === 0) resetPassword({email, captchaValue});
            else if (modalIdFromLogin === 2) resetOTP({email, captchaValue});
            actionCloseModal();
          }
          break;
        case 1:
          if (isEmailValid && token && isPasswordValid && passwordVerification === password) {
            register({email,password,token, captchaValue});
            actionCloseModal();
          }
          break;
        case 3:
          if (isEmailValid && token && isPasswordValid && passwordVerification === password) {
            updatePassword({email,password,token, captchaValue});
            actionCloseModal();
          }
          break;
        default:
          break;
      }
    }

    const actionCloseModal = () => {
        setModalId(-1);
        setCaptchaValue('');
    }

    const updateCaptchaValue = (value: string | null) => {
      if (value !== null) {
        setCaptchaValue(value);
      } else {
        setCaptchaValue('');
      }
    }

    const isTokenValid = token !== '';
    const isConfirmPasswordValid = passwordVerification !== '';
    const isConfirmedPassword = passwordVerification === password;

    const validationDisable = (): boolean => {
      let res = false;
      if (modalIdFromLogin === 0 || modalIdFromLogin === 2) {
        res = !isEmailValid || captchaValue === '';
      } else if (modalIdFromLogin === 1) {
        res = !isEmailValid || !isTokenValid || !isPasswordValid ||
          !isConfirmPasswordValid || captchaValue === '';
      }
      return res;
    }

    const isValidationDisabled = validationDisable();
    const buttonText = (modalIdFromLogin === 0 || modalIdFromLogin === 2) ? 'Valider' : 'M\'enregistrer';
    let modalTitle = '';
    let modalClass = '';
    switch(modalIdFromLogin) {
      case 0:
        modalTitle = 'Mot de passe oublié';
        modalClass = 'littleModal';
        break;
      case 1:
        modalTitle = 'Première connexion';
        modalClass = 'bigModal';
        break;
      case 2:
        modalTitle = 'Regénérer un nouveau mot de passe temporaire';
        modalClass = 'littleModal';
        break;
      case 3:
        modalTitle = 'Changement de mot de passe'
        modalClass = 'mediumModal';
        break;
    }

    modalClass += ' modal-wrapper';

    return (
        <Modal
            isShowing={modalIdFromLogin !== -1}
            modalClass={modalClass}
            hide={actionCloseModal}
            title={modalTitle}
        >
        <>
          <form className="changeInfos">
            {modalIdFromLogin === 0 ? (
              <>
                <p className="long_intro">{`Si vous avez perdu / oublié votre mot de passe, vous pouvez le réinitialiser en indiquant l'email
                que vous avez indiqué lors de votre commande.`}<br/>
                Suivez ensuite les différentes étapes pour définir un nouveau mot de passe et accéder à vos enregistrements.</p>
                <LabelledTextField isError={!isEmailValid && email !== ''} label="Identifiant" id="emailModal2" name="email" value={email} onChange={setEmail}/>
                { !isEmailValid && email !== '' ? (<p className="infoModalError">Merci de renseigner une adresse mail valide</p>):null }
              </>
            ): null}
            {modalIdFromLogin === 1 ? (
              <>
                <p className="intro">Adresse email</p>
                <LabelledTextField isError={!isEmailValid && email !== ''} label="" placeholder="Identifiant" id="email" name="email" value={email} onChange={setEmail}/>
                { !isEmailValid && email !== '' ? (<p className="infoModalError">Merci de renseigner une adresse mail valide</p>):null }
                <p className="info_temp_mdp">{PASSWORD_LABEL}</p>
                <LabelledTextField isError={!isTokenValid && token !== ''} label="" placeholder="Mot de passe provisoire" id="token" name="token" value={token} onChange={setToken}/>
                { !isTokenValid && token !== '' ? (<p className="infoModalError">Merci de renseigner votre mot de passe provisoire</p>):null }
                <button className="link_expired_mdp" onClick={() => {
                  setModalId(2);
                }}>Mot de passe temporaire expiré ?</button>
                <LabelledTextField isError={!isPasswordValid && password !== ''} label={NEW_PASSWORD_LABEL} id="passwordModal" placeholder = "Mot de passe" isPassword value={password} onChange={setPassword}/>
                { !isPasswordValid && password !== '' ? (<p className="infoModalError">{`Merci de renseigner un nouveau mot de passe comprenant minimum 8 caractères,
                  1 minuscule, 1 majuscule, 1 chiffre ou caractère spécial : @$!%*#?&`}</p>):null }
                <LabelledTextField isError={(!isConfirmPasswordValid || !isConfirmedPassword) && passwordVerification !== ''} label={CONFIRM_PASSWORD_LABEL} id="password-verification" placeholder = "Confirmation de mot de passe" isPassword value={passwordVerification} onChange={setPasswordVerification}/>
                { !isConfirmPasswordValid && passwordVerification !== '' ? (
                  <p className="infoModalError">Merci de confirmer votre mot de passe</p>
                  ) : ( !isConfirmedPassword ?
                    (<p className="infoModalError">Il y a une erreur dans la confirmation du mot de passe</p>):null
                )}
              </>
            ): null}
            {modalIdFromLogin === 2 ? (
              <>
                <p className="long_intro">Votre identifiant est votre email de contact que vous avez communiqué lors de votre commande.<br/>
                Le mot de passe temporaire est valable 30 minutes.</p>
                <LabelledTextField isError={!isEmailValid && email !== ''} label="Identifiant" id="emailModal" name="email" value={email} onChange={setEmail}/>
                { !isEmailValid && email !== '' ? (<p className="infoModalError">Merci de renseigner une adresse mail valide</p>):null }
              </>
            ): null}
             {modalIdFromLogin === 3 ? (
              <>
                <p className="intro">Adresse email</p>
                <LabelledTextField isError={!isEmailValid && email !== ''} label="" placeholder="Identifiant" id="email" name="email" value={email} onChange={setEmail}/>
                { !isEmailValid && email !== '' ? (<p className="infoModalError">Merci de renseigner une adresse mail valide</p>):null }
                <LabelledTextField isError={!isPasswordValid && password !== ''} label={NEW_PASSWORD_LABEL} id="passwordModal" placeholder = "Mot de passe" isPassword value={password} onChange={setPassword}/>
                { !isPasswordValid && password !== '' ? (<p className="infoModalError">{`Merci de renseigner un nouveau mot de passe comprenant minimum 8 caractères,
                  1 minuscule, 1 majuscule, 1 chiffre ou caractère spécial : @$!%*#?&`}</p>):null }
                <LabelledTextField isError={(!isConfirmPasswordValid || !isConfirmedPassword) && passwordVerification !== ''} label={CONFIRM_PASSWORD_LABEL} id="password-verification" placeholder = "Confirmation de mot de passe" isPassword value={passwordVerification} onChange={setPasswordVerification}/>
                { !isConfirmPasswordValid && passwordVerification !== '' ? (
                  <p className="infoModalError">Merci de confirmer votre mot de passe</p>
                  ) : ( !isConfirmedPassword ?
                    (<p className="infoModalError">Il y a une erreur dans la confirmation du mot de passe</p>):null
                )}
                <button className="link_expired_mdp" onClick={() => {
                  setModalId(0);
                }}>Lien expiré ?</button>
              </>
            ): null}
            <div className="captcha">
              <ReCAPTCHA
                sitekey={captchaKey}
                onChange={updateCaptchaValue}
                />
            </div>
          </form>
          <Button
            className="button-form linkto"
            isDisabled={isValidationDisabled}
            onClick={onLoginClick}
            label={buttonText}
            />
        </>
      </Modal>
    );
}

export default LoginModal;