import React from "react";
import { Link, useNavigate } from "react-router-dom";
import contractsImg from "../../assets/images/SOUSCRIPTION_RGB.jpg";
import firstHelpKitImg from "../../assets/images/DIAGNOSTIC_RGB.jpg";
import soundImg from "../../assets/images/LECTURE_RGB.jpg";

import "../../css/home.css";

type Props = {userFirstName?: string , logout: () => void}

const Home = (props: Props) => {
    const {userFirstName = "", logout} = props;
    const navigate = useNavigate();
    const onLogout = () => {
      logout();
      navigate("/login", { replace: true });
    }
    return (
      <div className='main-home'>
        <div className="intro">
          <h3>Bienvenue <span className="firstName">{userFirstName}</span></h3>
          <p>
            <Link to="/login" onClick={onLogout} >Se déconnecter</Link>
          </p>
        </div>
        <div className="sections">
          <Link to="/contracts">
            <img src={contractsImg} alt="contracts" />
            <p>Visualiser mes accords</p>
          </Link>
          <Link to="/contracts?load=true">
            <img src={soundImg} alt="son"/>
            <p>Ecouter mon dernier accord</p>
          </Link>
        {/* TODO Common Contract */}
        <a href="https://assistance.sfr.fr/" target="_blank" rel="noreferrer noopener">
          <img src={firstHelpKitImg} id="firstHelpKitImg" alt="besoin d'aide"/>
          <p>{`Besoin d'aide ?`}</p>
          <p>{"> Rendez-vous sur assistance.sfr.fr"}</p>
        </a>
        <a href="https://www.sfr.fr" target="_blank" className="bg-sfr" rel="noreferrer noopener">
          <div id="discover_last_offers">
            <p>Découvrez nos<br/>dernières offres</p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Home;