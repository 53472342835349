import { useSnackbar } from 'notistack';
import React, {useEffect, useState} from 'react';
import { getContracts } from '../api/customers';
import Contracts from "../components/4-pages/Contracts";
import { Contract, User } from '../types';
import { convertContractFromAPI } from '../util/convert';

const AUTHORIZATION_ERROR_MESSAGE = "Vos identifiants ont expirés. Veuillez vous reconnecter.";

const ContractsContainer = (props: {user: User, logout: () => void}) => {
    const {user, logout} = props;
    const [contracts, setContractsAPI] = useState<Contract[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        function onAuthorizationError() {
            enqueueSnackbar(AUTHORIZATION_ERROR_MESSAGE, { 
                variant: 'error',
            })
            logout();
        }

        async function setContracts() {
            const apiContracts = await getContracts(user, onAuthorizationError)
            setContractsAPI(apiContracts.map(apiContract => convertContractFromAPI(apiContract, user)));
        }
        setContracts()
    }, [setContractsAPI, user, logout, enqueueSnackbar]);
    const userFirstName = user.firstName;
    return <Contracts userContracts={contracts} userFirstName={userFirstName} logout={logout} />
}

export default ContractsContainer;