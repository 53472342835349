import React, {useState} from "react";
import { Link } from "react-router-dom";

import "../../css/style.css";
import "../../css/contrats.css";
import { Contract, CustomerInfo, SearchInfo } from "../../types";
import Button from "../1-atoms/Button";
import LabelledTextField from "../1-atoms/LabelledTextField";
import ContractsTable from "../2-molecules/ContractsTable";

type Props = {
  agentId: string,
  customer?: CustomerInfo,
  contracts: Contract[],
  lookupContracts: (SearchInfo: SearchInfo) => void,
  logout: () => void
}

const ContractsLookup = (props: Props) => {
  const {agentId, customer, contracts, lookupContracts, logout} = props;

  const [phoneNumber, editPhoneNumber] = useState(customer?.phoneNumber ?? "");
  const [email, editEmail] = useState(customer?.email ?? "");

  const search = () => {
    lookupContracts({phoneNumber, email})
  }

  return (
    <div className="main-contracts">
      <div className="intro">
        <h3>Bienvenue Agent</h3>
        <p><Link to="/agent/login" onClick={logout}>Se déconnecter</Link></p>
      </div>
  
      <br/><br/>
      
      <div className="contrats_agent contrats">
        <div className="contrat">
          <h4 className="">Rechercher un contrat</h4>
          <div className="content liste">
            <form action="">
              <LabelledTextField 
                label="Agent ID"
                id="agentId"
                name="agentId"
                placeholder = "Identifiant"
                value={agentId}
                onChange={() => {}}
                readOnly
              />
              <br/>

              <LabelledTextField 
                label="Téléphone du client :"
                id="clientPhone"
                name="clientPhone"
                placeholder = "Téléphone du client"
                value={phoneNumber}
                onChange={editPhoneNumber}
              />
              <br/>

              <LabelledTextField 
                label="Email du client :"
                id="clientEmail"
                name="clientEmail"
                placeholder="Email du client"
                value={email}
                onChange={editEmail}
              />
              <br/>
              <br/>
              <Button className="" 
                label="Rechercher"
                onClick={search}
              />
            </form>
          </div>
        </div>

        <ContractsTable contracts={contracts}/>
      </div>
    </div>
  )
}

export default ContractsLookup;