import React from "react";
import {Button as RButton} from "reakit"
import {DefaultProps} from "../../types";

type Props = {
    onClick: () => void;
    isDisabled?: boolean;
    className?: string;
    label: string;
} & DefaultProps

const Button = (props: Props) => {
    const { onClick, className, isDisabled = false, label} = props;
    return (
        <RButton
            onClick={onClick}
            disabled={isDisabled}
            className={className}
        >
            {label}
        </RButton>
    )
}

export default Button;