import React, {useEffect, useState} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import {useAuthentication} from "../hooks/auth";
import LoginContainer from "../containers/LoginContainer";
import ContractsContainer from "../containers/ContractsContainer";
import HomeContainer from "../containers/HomeContainer";
import CustomerFileContainer from "../containers/CustomerFileContainer";
import { SnackbarProvider } from 'notistack';
import sfrLogo from "../assets/images/SFR_Logo_RVB.png";
import ContractsLookupContainer from "../containers/ContractsLookupContainer";

const App = () => {
  const {user, login, logout}  = useAuthentication();
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  useEffect(() => {
    return () => {
      if (!stayLoggedIn) {
        logout();
      }
    };
  }, [logout, stayLoggedIn])

  return (
    <SnackbarProvider>
      <div className="App">
        <header>
          <img src={sfrLogo} alt="logo"/>
          <h2>Mon espace accord</h2>
        </header>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <main>
          <BrowserRouter>
            <Routes>
                  {
                    user === undefined && (
                      <>
                        <Route path="login/update/:token" element={<LoginContainer login={login} setStayLoggedIn={setStayLoggedIn} isUpdate={true}/>} />
                        <Route path="login/:token" element={<LoginContainer login={login} setStayLoggedIn={setStayLoggedIn}/>} />
                        <Route path="login" element={<LoginContainer login={login} setStayLoggedIn={setStayLoggedIn}/>} />
                        <Route path="agent/login" element={<LoginContainer isAgent login={login} setStayLoggedIn={setStayLoggedIn}/>} />
                        <Route
                          path="*"
                          element={<Navigate to="login" />}
                        />
                      </>
                    )
                  }
                  {
                    user !== undefined && user.type === "CUSTOMER" &&
                    <>
                      <Route path="contracts" element={<ContractsContainer user={user} logout={logout} />} />
                      <Route path="home" element={<HomeContainer user={user} logout={logout}/>} />
                      <Route
                        path="*"
                        element={<Navigate to="home" />}
                      />
                    </>
                  }
                  {
                    user !== undefined && user.type === "AGENT" &&
                    <>
                    <Route path="contracts/lookup" element={<ContractsLookupContainer user={user} logout={logout} />} />
                      {/* TODO: Customer By Id */}
                      <Route path="customers/file" element={<CustomerFileContainer user={user} logout={logout}/>} />
                      <Route
                        path="*"
                        element={<Navigate to="contracts/lookup" />}
                      />
                    </>
                }
            </Routes>
          </BrowserRouter>
        </main>
      </div>
    </SnackbarProvider>
  );
}

export default App;
