import React from "react";
import {Input} from "reakit"; 
import { DefaultProps } from "../../types";

export type TextFieldProps = DefaultProps & {
    isRequired?: boolean;
    isDisabled?: boolean;
    placeholder?: string; // TODO ?
    value: string;
    isPassword?: boolean;
    isError?: boolean;
    onChange: (value: string) => void;
    onBlurAction?: () => void;
}

const TextField = (props: TextFieldProps) => {
    const {isRequired = false, isError = false, onBlurAction,className, isDisabled = false, value, isPassword = false, placeholder, onChange, ...defaultProps} = props;

    const onChangeProp = (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        onChange(value);
    };

    return (
    <Input
        disabled={isDisabled}
        required={isRequired}
        placeholder={placeholder}
        value={value}
        type={isPassword? "password" : "text"}
        onChange={onChangeProp}
        {...defaultProps}
        onBlur={onBlurAction}
        style={{ fontFamily: 'FontAwesome, Poppins'}}
        className={isError ? 'error' : className}
    />
    )
}

export default TextField;