import React from "react";
import { Contract, Contract as ContratType } from "../../types";
import Contrat from "../2-molecules/Contrat";

type Props = {
    contractsByDate: ContratType[];
    playingIndex: string;
    setPlayingIndex: (playingIdx: string) => void;
}

export const ContractsGroup = (props: Props) => {
    const { contractsByDate, setPlayingIndex, playingIndex } = props;

    return (
        <div className="contrat">
            <h4>{new Date(contractsByDate[0].date).getFullYear()}</h4>
            {contractsByDate.map((userContract: Contract) => (
                <Contrat
                    key={userContract.id}
                    contractData={userContract}
                    isPlaying={userContract.id === playingIndex}
                    setPlaying={() => setPlayingIndex(userContract.id === playingIndex ? "" : userContract.id)}
                />
            ))}
        </div>
    )
}
