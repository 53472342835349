import React from "react";
import TextField, {TextFieldProps} from "./TextField";

type Props = TextFieldProps & {
    label: string;
    id: string;
    onBlurAction?: () => void;
}

const LabelledTextField = (props: Props) => {
    const {label, id, onBlurAction, ...textFieldProps} = props;

    return (
        <>
            <label htmlFor={id} className={`label-${id}`}>
                {label}
            </label>
            <TextField
                id ={id}
                {...textFieldProps}
                onBlurAction={onBlurAction}
            />
        </>
    )
}

export default LabelledTextField;