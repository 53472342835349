import { User } from "../types"

export async function post(url: string, body: any, authorization? : string) {
    const res = await fetch(
        url,
        {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authorization ? `Bearer ${authorization}`  : undefined
            } as HeadersInit,
            cache: "default",
            body: JSON.stringify(body)
        }
    )
    if (!res.ok) {
        return await res.text().then(text => { throw new Error(text) })
    } else {
        try {
            return await res.json();
        } catch { // working when there is no body
            return undefined
        }
    }
}

export async function authorizedPost(url: string, body: any, user: User) {
    return await post(url, body, user.accessToken)
}

export async function get(url: string, authorization? : string, onAuthorizationError?: () => void) {
    const res = await fetch(
        url,
        {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authorization ? `Bearer ${authorization}`  : undefined
            } as HeadersInit,
            cache: "default"
        }
    )
    if (!res.ok) {
        if (res.status === 401 && onAuthorizationError) {
            return onAuthorizationError();
        }
        return await res.text().then(text => { throw new Error(text) })
    } else {
        try {
            return await res.json();
        } catch { // working when there is no body
            return undefined
        }
    }
}

export async function authorizedGet(url: string, user: User, onAuthorizationError?: () => void) {
    return await get(url, user.accessToken, onAuthorizationError);
}