import React, { useState } from 'react';
import CustomerFile from "../components/4-pages/CustomerFile"
import { CustomerInfo, User } from '../types';

const CustomerFileContainer = (props: {user: User, logout: () => void}) => {
    const [customerInfos, setCustomer] = useState<CustomerInfo>({
        lastName: '',
        firstName: '',
        phoneNumber: '',
        email:'',
        agentId: 0
    });
    // const customerId = '0'; // TODO : set right customer id
    // const getInfosCustomer = (a: string, b: any) => {}
    // useEffect(() => {
    //     getInfosCustomer(customerId, setCustomer);
    // }, [setCustomer]);

    return <CustomerFile customerInfo={customerInfos} updateCustomerInfo={setCustomer} agentId={props.user.id} logout={props.logout} />
}

export default CustomerFileContainer;