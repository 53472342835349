import React, {useState, useEffect, useMemo} from "react";
import { Link } from "react-router-dom";
import firstHelpKitImg from "../../assets/images/DIAGNOSTIC_RGB.jpg";

import "../../css/style.css";
import "../../css/contrats.css";
import { Contract } from "../../types";
import { ContractsGroup } from "../2-molecules/ContractsGroup";
import Contrat from "../2-molecules/Contrat";

type Props = {
    userContracts: Contract[];
    userFirstName?: string;
    logout: () => void;

}

interface IUserContractsBydate {
    [key: string]: any
}

const Contracts = (props: Props) => {
    const {userContracts, userFirstName = "", logout} = props;

    const [playingIndex, setPlayingIndex] = useState('');

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const load = params.get('load');

    const lastContract: Contract = useMemo(() => (
        userContracts.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
    ), [userContracts])
     
    useEffect(() => {
        // if load is true, we have to set last contract id to current playing index
        if (load  && lastContract !== undefined) {
            setPlayingIndex(lastContract.id);
        }
    }, [setPlayingIndex, lastContract, load]);
    
    const userContractsByDate = useMemo( () => {
        let inProgessContracts: IUserContractsBydate = {};
        for (const i in userContracts) {
            const year = new Date(userContracts[i].date).getFullYear();
            if (inProgessContracts[year] === undefined) {
                inProgessContracts[year] = [userContracts[i]];
            } else {
                inProgessContracts[year].push(userContracts[i]);
            }
        }
        return  Object.entries(inProgessContracts).sort((a, b) => a[1] - b[1]).reverse()
    }, [userContracts]);

    return (
        <div className="main-contracts">
            <div className="intro">
                <h3>Bienvenue <span>{userFirstName}</span></h3>
                <p>
                    <Link to="/home">{`Retour à l'accueil`}</Link> |<Link to="/login" onClick={logout}>Se déconnecter</Link>
                </p>
            </div>
            <div className="contrats">
                {load && lastContract !== undefined ? (
                     <div className="contrat">
                         <h4>{new Date(lastContract.date).getFullYear()}</h4>
                         <Contrat
                             contractData={lastContract}
                             isPlaying={lastContract.id === playingIndex}
                             setPlaying={() => setPlayingIndex(lastContract.id === playingIndex ? "" : lastContract.id)}
                         />
                     </div>
                    ): (
                        userContractsByDate.map((value: any[]): React.ReactNode => (
                            <ContractsGroup
                                key={(value[1][0] as Contract).id}
                                contractsByDate={value[1]}
                                playingIndex={playingIndex}
                                setPlayingIndex={setPlayingIndex}
                            />
                        ))
                    ) 
                }
            </div>
            <a href="https://assistance.sfr.fr/" className="contracts-help" target="_blank" rel="noreferrer noopener">
                <div className="help">
                    <img src={firstHelpKitImg} className="img-contract" alt="besoin d'aide" />
                    <div className="texts">
                        <h3>{`Besoin d'aide ?`}</h3>
                        <p>
                            {"> Rendez-vous sur assistance.sfr.fr"}
                        </p>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default Contracts;