import { ConnexionInfo, User, APIContract } from "../types";
import { post, authorizedGet } from "./rest";

const CUSTOMER_API_URL = process.env.REACT_APP_API_HOST + "api/v1/customers";

const addOmniInfo = (info: {email?: string, phoneNumber?: string, captchaValue: string}, isOTP: boolean) => ({
    tenantId: process.env.REACT_APP_TENANT_ID,
    campaignSettingsId: isOTP ? process.env.REACT_APP_CS_OTP : process.env.REACT_APP_CS_PASSWORD,
    ...info
})

export async function loginCustomer(connexionInfo: ConnexionInfo): Promise<{token:string; user: User}> {
    return await post(`${CUSTOMER_API_URL}/login`, connexionInfo)
}

export async function registerCustomer(connexionInfo: ConnexionInfo): Promise<{token:string; user: User}> {
    return await post(`${CUSTOMER_API_URL}/register`, connexionInfo)
}

export async function updateCustomer(connexionInfo: ConnexionInfo): Promise<{token:string; user: User}> {
    return await post(`${CUSTOMER_API_URL}/updatePassword`, connexionInfo)
}

export async function resetPassword(connexionInfo: {email?: string, phoneNumber?: string, captchaValue: string}): Promise<void> {
    return await post(`${CUSTOMER_API_URL}/resetPassword`, addOmniInfo(connexionInfo, false))
}

export async function resetOTP(connexionInfo: {email?: string, phoneNumber?: string, captchaValue: string}): Promise<void> {
    return await post(`${CUSTOMER_API_URL}/resetOTP`, addOmniInfo(connexionInfo, true))
}

export async function getContracts(user: User, onAuthorizationError?: () => void): Promise<APIContract[]> {
    return await authorizedGet(CUSTOMER_API_URL + '/transactions', user, onAuthorizationError) as APIContract[];
}

export function getContractAudioURL(user: User, transactionId: string): string {
    return  `${CUSTOMER_API_URL}/transaction/${transactionId}?jwt=${encodeURIComponent("Bearer "+ user.accessToken)}`;
}
