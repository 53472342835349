import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import '../../css/modal.css';

interface IModalProps {
    isShowing: boolean,
    hide: () => void,
    title: string,
    children?: ReactNode,
    height?: string,
    modalClass?: string,
}

export const Modal = ({ isShowing, hide, modalClass, title, children }: IModalProps ) => 
    (isShowing) ? ReactDOM.createPortal(
          <>
            <div className="modal-overlay">
              <div className={modalClass || ''}>
                <div className="modal-container">
                  <button
                      type="button"
                      className="modal-close-button"
                      onClick={hide}
                      >
                      <span>&times;</span>
                  </button>
                  <div className="modal">
                    <div className="modal-header">
                      <h2>{title}</h2>
                    </div>
                    <div className="modal-body">{children}</div>
                  </div>
                </div>
              </div>
            </div>
          </>,
          document.body)
          : null

Modal.propTypes = {
    isShowing: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired
};