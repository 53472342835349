import React, {useState } from 'react';
import ContractsLookup from "../components/4-pages/ContractsLookup"
import { Contract, SearchInfo, User } from '../types';
import {getContracts} from "../api/agents"
import { convertContractFromAPI } from '../util/convert';
const ContractsLookupContainer = (props: {user: User, logout: () => void}) => {
    const {user, logout} = props;
    const [contracts, setContracts] = useState<Contract[]>([]);


const lookupContracts = async (searchInfo: SearchInfo) => {
    const apiContracts = await getContracts(user, searchInfo)
    setContracts(apiContracts.map(apiContract => convertContractFromAPI(apiContract, user)));
}

return <ContractsLookup agentId={props.user.id} contracts={contracts} lookupContracts={lookupContracts} logout={logout}/>
}
export default ContractsLookupContainer;