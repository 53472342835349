import { APIContract, ConnexionInfo, SearchInfo, User } from "../types";
import { post, authorizedPost } from "./rest";


const USER_API_URL = process.env.REACT_APP_API_HOST +  "api/v1/users";

export function loginUser(connexionInfo: ConnexionInfo) {
    return post(`${USER_API_URL}/login`, connexionInfo)
}

export async function getContracts(user: User, infos: SearchInfo ): Promise<APIContract[]> {
    return await authorizedPost(USER_API_URL + '/transactions/', infos, user);
}
 