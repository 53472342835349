import React, {useState} from "react";
import LabelledTextField from "../1-atoms/LabelledTextField";
import Checkbox from "../1-atoms/Checkbox";
import "../../css/login.css"
import locker from "../../assets/images/SFR SECURITE PRO_RGB.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../1-atoms/Button";
import LoginModal from "../2-molecules/LoginModal";

const EMAIL_LABEL =  "Numéro de ligne mobile, email ou NeufID";
const AGENT_EMAIL_LABEL =  "Veuillez saisir votre login et mot de passe";
const PASSWORD_LABEL =  "Saisissez votre mot de passe provisoire";
const CONNECT_LABEL = "Rester connecté";

const captchaKey = process.env["REACT_APP_RECAPTCHA_KEY"] ?? "  ";
type Props = {
  token?: string;
  isAgent: boolean;
  isUpdate: boolean;
  register: (registerInfo: {email: string; password: string, token: string, captchaValue: string}) => void;
  updatePassword: (updateInfo: {email: string; password: string, token: string, captchaValue: string}) => void;
  login: (loginInfo:{email: string; password: string, stayConnected?: boolean, captchaValue: string}) => void;
  resetOTP: (infos: {email?: string, phoneNumber?: string, captchaValue: string}) => void;
  resetPassword: (infos: {email: string, captchaValue: string}) => void;
}

const LoginPage = (props: Props) => {
    const {isAgent, isUpdate, login, token, register,  resetOTP, resetPassword, updatePassword} = props;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [stayConnected, setStayConnected] = useState(false);
    const [captchaValue, setCaptchaValue] = useState("");
    const validationPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|[@$!%*#?&]))[a-zA-Z\d@$!%*#?&]{8,}$/;
    const validationEmail = /\w+(\w|\.)*@\w+\.\w{2,3}/;

    const getInitModalState = () => {
      if (isUpdate) {
        return 3;
      } else {
        return token ?  1 : -1;
      }
    };
    const initModalState = getInitModalState();

    const [modalId, setModalId] = useState(initModalState);
    const isEmailValid = validationEmail.test(email.toLowerCase());
    const isPasswordValid = validationPassword.test(password);

    const onLoginClick = async () => {
      if (modalId === -1 && isPasswordValid && isEmailValid) {
        login({email,password,stayConnected, captchaValue});
      }
    }

    const updateCaptchaValue = (value: string | null) => {
      if (value !== null) {
        setCaptchaValue(value);
      } else {
        setCaptchaValue('');
      }
    }

    const isValidationDisabled = !isEmailValid || !isPasswordValid || captchaValue === '';

    return (
    <>
      <LoginModal
        tokenProps={token}
        setModalId={setModalId}
        modalIdFromLogin={modalId}
        login={login}
        register={register}
        updatePassword={updatePassword}
        resetOTP={resetOTP}
        resetPassword={resetPassword}
        />
      <div className="phishing-infos">
        <div className="tooltip">Info Phishing<img src={locker} id="cadenas" alt="cadenas"/>
          <div className="tooltiptext">
            <a target="_blank" href="https://assistance.sfr.fr/sfrmail-appli/phishing-spam/proteger-phishing.html" rel="noreferrer noopener"><span>&#10146;</span> Comment se protéger du phishing et signaler un e-mail suspect à SFR ?</a>
            <a target="_blank" href="https://assistance.sfr.fr/sfrmail-appli/phishing-spam/email-phishing-sms-frauduleux.html" rel="noreferrer noopener"><span>&#10146;</span> Comment reconnaître et signaler un e-mail de phishing, un SMS ou appel frauduleux ?</a>
          </div>
        </div>
      </div>
      <div className="login">
        <form>
          <h2> {
            isAgent ?
            "Espace Accord - Login Agent" :
            "Mon espace Accord"
          }
          </h2>
          <LabelledTextField 
              isError={!isEmailValid && email !== ''} 
              label={isAgent ? AGENT_EMAIL_LABEL : EMAIL_LABEL} 
              id="email" 
              name="email" 
              placeholder = "&#xF007;    Identifiant"
              className="icon-input"
              value={email} 
              onChange={setEmail}/>
          { !isEmailValid && email !== '' ? (<p className="infoModalError">Merci de renseigner une adresse mail valide</p>):null }

          <LabelledTextField 
              isError={!isPasswordValid && password !== ''}
              label={PASSWORD_LABEL} 
              id="password" 
              placeholder = "&#xF084;   Mot de passe" 
              isPassword 
              value={password} 
              onChange={setPassword}/>
          { !isPasswordValid && password !== '' ? (<p className="infoModalError">{`Merci de renseigner un nouveau mot de passe comprenant minimum 8 caractères,
                  1 minuscule, 1 majuscule, 1 chiffre ou caractère spécial : @$!%*#?&`}</p>):null }
          {
            !isAgent &&
            <>
              <div className="connect">
                <Checkbox id="connect" isChecked={stayConnected} onChange={() => setStayConnected(!stayConnected)} label={CONNECT_LABEL} />
              </div>
            </>
          }
          <div className="captcha">
            <ReCAPTCHA
              sitekey={captchaKey}
              onChange={updateCaptchaValue}
              />
          </div>
        </form>
        <Button
          isDisabled={isValidationDisabled}
          className="linkto ppal_link"
          onClick={onLoginClick}
          label="Me connecter"
          />
        {
          !isAgent &&
          (
            <div className="help">
              <h3>{`Besoin d'aide`}</h3>
              <div className="links">
                <div>
                  <button className="link-password" id="forgotten_password" onClick={() => {
                    setModalId(0);
                  }}>
                    <span>&#xF105;</span> Mot de passe oublié
                  </button>
                </div>
                <div>
                  <button className="tooltip link-id" ><span>&#xF105;</span> Identifiant oublié
                    <div className="tooltiptext">
                      <p>Votre identifiant est votre adresse email de contact que vous avez communiqué lors de votre commande</p>
                    </div>
                  </button>
                </div>
                <div>
                  <button className="link-first" id="first_connection" onClick={() => {
                    setModalId(1);
                  }}>
                    <span>&#xF105;</span> Première connexion</button>
                </div>
              </div>
            </div>
           )
        }
      </div>
    </>
  )
}

export default LoginPage;