import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Contract } from "../../types";
import Button from "../1-atoms/Button";

// TODO: Agent view
const son = "https://download.samplelib.com/mp3/sample-3s.mp3";

const ContractsTable = (props: {contracts: Contract[]}) => {
    const {contracts} = props;
    const [audio] = useState( new Audio(son));
    const [isPlaying, setIsPlaying] = useState("");
    
    const listenContract = (contractId: string) => {
        if (isPlaying === contractId) {
            audio.pause()
            audio.currentTime = 0;
            setIsPlaying("")
        } else {
            audio.play()
            audio.currentTime = 0;
            setIsPlaying(contractId)
        }
    };

    return (
        <div className="contrat searchresults searchoff" id="searchresults">
            <h4 className="">Résultat de la recherche</h4>
            <div className="content liste">
            <table>
                <tr>
                    <th>Date contrat</th>
                    <th>Actions</th>
                </tr>
                {
                    contracts.map((contract: Contract) => (
                        <tr key={contract.id}>
                            <td>{contract.date}</td>
                            <td>
                                <Button label="Ecouter" onClick={() => listenContract(contract.id)}/>
                                <br />
                                {/* TODO: Send to right customer*/}
                                <Link to="#">Modifier</Link>
                            </td>
                        </tr>
                    ))
                }
            </table>
            </div>
        </div>
    )
}

export default ContractsTable;