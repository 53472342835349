import React from "react";
import {Checkbox as RCheckbox} from "reakit";
import {DefaultProps} from "../../types";

type Props = {
    onChange: () => void;
    isDisabled?: boolean;
    label?: string;
    isChecked: boolean;
} & DefaultProps;

const Checkbox = (props: Props) => {
    const { id, onChange,isChecked, isDisabled, label: labelProp, ...defaultprops} = props;
    return (
        <label htmlFor={id} {...defaultprops}>
            <RCheckbox
            color="red"
            className="checkbox-custom-red"
            id={id}
            name={id}
            onChange={onChange}
            disabled={isDisabled}
            checked={isChecked}
        />
            {labelProp}
        </label>
    )
}

export default Checkbox;